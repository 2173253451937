@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');
body {
  font-family: 'Poppins', sans-serif;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 834px;
  height: 100vh;
  margin: 0 auto;
  text-align: center;
  background-color: #f0f9f1;
}
