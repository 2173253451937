@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.text-container{
  font-size: 32px;
  color: #9BA3AC;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}


.logs{
  margin-top: 30px;
}

.subtitle{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50vw;
  min-width: 550px;
}

.your-logs{
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

#icon{
  font-size: 30px;
  margin: auto;
  margin-left: 0px;
}

#logs-text{
  position: relative;
}
