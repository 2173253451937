input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
  }

input[type="radio"] + label span {
    transition: background .2s,
      transform .2s;
}

input[type="radio"] + label span:hover,
input[type="radio"] + label:hover span{
  transform: scale(1.2);
} 

input[type="radio"]:checked + label span {
  background-color: #FF9C55;
  box-shadow: 0px 0px 0px 2px white inset;
}

input[type="radio"]:checked + label{
   color: #FF9C55;
}


.filter-parent{
    display: flex;
    align-items:center;
    justify-content: center;

    padding: 20px;
}

.filter-form{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 40px;

    position: static;
    width: 620px;
    height: 90px;
    left: 0px;
    top: 68px;

    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;
}

.filter-form > * {
    background-color: #FFFFFF;

    width: 100%;
    height: 100%;

    font-size: 32px;
}

.locationItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 40px;

    position: static;
    width: 620px;
    height: 90px;
    left: 0px;
    top: 68px;

    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;
}

.locationItem > * {
    background-color: #FFFFFF;

    width: 100%;
    height: 100%;

    font-size: 32px;

    cursor:pointer;
}

.text-primary-locationForm{
    position: static;
    width: 202px;
    height: 48px;
    left: 0px;
    top: 0px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;

    color: #555555;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 20px 0px;
}


.location-name-form{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px 40px;

    width: 620px;
    height: 90px;
    
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    background-color: #FFFFFF;
    font-size: 32px;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;
}

.food-type{
    position: static;
    width: 165px;
    height: 48px;
    left: 0px;
    top: 0px;

    font-family: Poppins;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 48px;

    color: #555555;

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 20px 0px;
}

.select-parent >* {
    padding: 10px;
    font-size: 32;
}

.specify-item {
    display: block; 
    margin: 0 auto;
    text-align: center;
    right: 100px;
    padding: 10px 40px;
    
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;  
}

.continue_button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 60px;

    background: #FF9C55;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
}

.newLocation > * {
    padding: 10px 40px;

    position: static;
    width: 620px;
    height: 90px;
    left: 0px;
    top: 68px;

    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    background-color: #FFFFFF;
    font-size: 32px;

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0px;    
}

.main > * {
    display: flex;
    align-items:center;
    flex-direction: column;
}
