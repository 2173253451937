@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.fa-icon{
  color: #ff9c55;
}

.bgimg{
  background-image: url('../../imgs/carrots.png');
  }

.greeting-box{
  background-color: rgba(255, 255, 255, 0.763);
  height: 220px;
  width: 100%;
  box-shadow: 0 2px 20px 2px rgba(0,0,0,0.2);
}

.greeting{
  padding-top: 80px;
  padding-bottom: 20px;
  padding-left: 10%;
  float: left;
}

.greeting h2{
  color: black;
  font-size: 48px;
  font-weight: 600;
}

.weight{
  /*background-color: #FF9C55;*/
  position: relative;
  display: flex;
  width: 63%;
  border-radius: 15px;
  height: 125px;
  box-shadow: 0px 4px 10px rgba(0,0,0,.25);
  top: -30px;
  z-index:10;
}

.lbs{
  flex: 0 1 auto;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  vertical-align: center;
  margin-top: 40px;
}

.current-weight h3{
  color: white;
  font-size: 24px;
  font-weight: 400;
}

.lbs h2{
  color: white;
  font-size: 38px;
  font-weight: 600
}

.action{
  margin-top: -30px;
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 50%;
  border: none;
  filter: drop-shadow(0 4px 10px rgba(0,0,0,.25));
  border-radius: 0 0 15px 15px;
}

.action-item{
  vertical-align: middle;
  display: flex;
  align-items: center;
  gap: 30px;
  width: 250px;
}

.action button{
  background-color: white;
  font-weight: 700;
  font-size: 28px;
  height: 125px;
  width: 100%;
  box-shadow: inset 0px -4px 0 px rgba(0,0,0,.25);
  opacity: .95;
  border: none;
  border-bottom: 3px solid rgba(0,0,0,.25);
  font-family: 'Poppins', sans serif;
  display: flex;
  align-items: center;
  justify-content: center;
}

#bottom-button button{
  border-radius: 0 0 15px 15px;
  border: none;
}