.volunteer-card{
  border: 1px solid rgba(0,0,0,0.75);
  height: 260px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.25);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.volunteer-text{
  font-size: 20px;
  padding-bottom: 10px;
}
.pin-text{
  font-size: 15px;
}
.car-icon{
  font-size: 140px;
  margin-bottom: 20px;
}