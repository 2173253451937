@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');

/*
.container2 {
  background-image: url('../../imgs/carrots.png');
  width: 100%;
  height: 100vh;
  font-family: 'Poppins', sans serif;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
*/

.text-box {
  width: 460px;
  height: 525px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 2px 20px 2px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 350px;
  padding-top: 0px;
  padding-bottom: 15px;
}

.inputs {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 40px;
  margin-top: 20px;
}

.input-box {
  border-radius: 30px;
  height: 55px;
}

.input-box input {
  height: 35px;
  border-radius: 30px;
  width: 300px;
  border: 2px solid #929292;
  padding: 3px;
  padding-left: 30px;
  font-size: 17px;
}

.input-box button {
  background: #ff9c55;
  color: white;
  border: none;
  border-radius: 30px;
  width: 335px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.instructions h4 {
  font-size: 12px;
  color: #555555;
  margin-top: 8px;
}

.instructions {
  border-top: 4px solid #ff9c55;
  width: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
}
