.volunteer-card{
  border: 1px solid rgba(0,0,0,0.75);
  height: 260px;
  width: 200px;
  border-radius: 20px;
  box-shadow: 0px 4px 10px rgba(0,0,0,0.25);
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.volunteer-text{
  font-size: 20px;
  padding-bottom: 10px;
}
.pin-text{
  font-size: 15px;
}
.user-icon{
  font-size: 125px;
  margin-bottom: 20px;
}
#new-card{
  border: dashed grey;
  box-shadow: none;
}
.new-card-text{
  font-size: 25px;
  color: grey;
}
#modal{
  z-index: 10;
}
.form-name{
  background: white;
}

.input{
  margin-top: 10px;
}

#form-submit{
  width: 100px;
  color: white;
  background-color: rgb(255, 156, 85);
  padding-left: none;
  box-shadow: 0px 4px 14px rgba(0,0,0,0.1);
  border-radius: 10px;
  height: 38px;
  margin-top: 20px;
}

#X{
  color: grey;
  position: absolute;
  margin-left: 150px;
  margin-bottom: 200px;
  width: 30px;
  height: 30px;
}
