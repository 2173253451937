.main{
    display: flex;
    justify-content: center;
    align-items:center;
    flex-direction: column;
}

.main > *{
    display: flex;
    align-items:center;
    justify-content: center;
    flex-direction: column;
}

.NumberForm {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px 40px 0px 0px;
    
    position: static;
    width: 340px;
    height: 100px;
    left: 0px;
    top: 0px;

    background: white;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
    border-radius: 30px;
    
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 60px 0px;


}


.test{
    margin-left: auto;
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 33px;
    display: flex;
    align-items: flex-end;
    text-align: center;
}

.test1{
    text-align: center;

    position: relative;
    top: 25px;
    left: 10px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 52px;
    line-height: 71px;
    display: flex;
    align-items: center;
    text-align: center;
}

.grid-container {
    display: grid;
    padding-top: 2rem;
    padding-bottom: 2rem;
    grid-template-columns: 5rem 5rem 5rem;
    grid-gap: 1.5rem;
}

.grid-btn {
    background-color: #7db596;
    border-radius: 25px;
    border: 0px;
    box-shadow: 0px 5px 2px rgba(0, 0, 0, 0.4);
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 48px;
    color: #ffffff;
    width: 5rem;
    height: 5rem;
}
.grid-btn:active {
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.4);
    transform: translateY(2px);
}

.grid-btn-submit {
    background-color: #f0f9f1;
    border: 0px;
    font-family: 'Roboto';
    color: #176c3e;
    width: 5rem;
    height: 5rem;
}
.grid-btn-submit:active {
    transform: translateY(2px);
}

.submit_button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 60px;

    position: static;
    width: 263px;
    height: 84px;
    left: 38.5px;
    top: 680px;

    background: #FF9C55;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 30px;

    flex: none;
    order: 2;
    flex-grow: 0;
    margin: 60px 0px;

    font-family: Open Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 54px;
    display: flex;
    align-items: center;
    text-align: center;
    
    color: #FFFFFF;
}

.icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 4.5rem;
}
.delete {
    font-weight: bold;
    font-size: 2rem;
}
  